@import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,200;0,300;0,400;1,200;1,300;1,400&display=swap');


/* Home.css */
/* Home.css */

.icon-button {
  transition: filter 0.3s ease, opacity 0.3s ease, transform 0.3s ease;
}

body {
  overflow: hidden; /* Hide scrollbars */
}

.example::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.example {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.icon-button:hover {
  filter: brightness(85%); /* Slight darkening on hover */
}

.icon-button.clicked {
  /* Removed the filter to prevent affecting child elements like tooltips */
  transform: scale(0.96);
}

/* Apply filter only to the icons when the button is clicked */
.icon-button.clicked .info-icon,
.icon-button.clicked .refresh-icon {
  filter: brightness(50%);
}

.no-select {
  -webkit-touch-callout: none; /* Disable callout, e.g., phone numbers in Safari */
  -webkit-user-select: none;   /* Disable text selection in Safari */
  -khtml-user-select: none;    /* Disable text selection in Konqueror */
  -moz-user-select: none;      /* Disable text selection in Firefox */
  -ms-user-select: none;       /* Disable text selection in Internet Explorer/Edge */
  user-select: none;           /* Disable text selection in non-prefixed browsers */
}

/* Home.css */

.logo-container-home {
  transition: transform 0.1s ease-out, top 0.1s ease-out, left 0.1s ease-out;
}

.curate-text {
  transition: top 0.1s ease-out, opacity 0.1s ease-out;
}

.home-features {
  transition: opacity 0.1s ease-out, transform 0.1s ease-out;
}

/* Add transitions to other elements as needed */

.demo-button-home{
  overflow: hidden !important;
}

.no-scroll {
  overflow: hidden;
  height: 100vh;
  width: 100vw;
}

/* CSS: Home.css */

@keyframes gradientAnimation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.animated-gradient-text {
  /* background: linear-gradient(90deg, #ff6ec4, #7873f5, #79b8ff, #6ec1ff); */
  background: linear-gradient(90deg, #777, #aaa, #aaa, #999);

  background-size: 300% 300%;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  animation: gradientAnimation 6s ease infinite;
}


.fade-in {
    opacity: 1;
  }
  
  .fade-out {
    opacity: 0;
  }


  .background {
    position: absolute;
    overflow: hidden;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: linear-gradient(to right, #bdd2f4, #ECE9E6);
    display: flex;
    flex-grow: 1;
    z-index: -10;
}

.background div {
    position: absolute;
    border-radius: 100%;
    height: 0;
    filter: blur(240vw);
    opacity: 0.4;
}

.background div:nth-child(1) {
    background: linear-gradient(132deg, #1FCFC3 0.00%, #1F91CF 100.00%);
    width: 45%;
    padding-top: 45%;
    left: 0%;
    top: 0%;
    transform: translateX(-50%) translateY(-50%);
}

.background div:nth-child(2) {
    background: linear-gradient(132deg, #0800FF 0.00%, #445074 100.00%);
    width: 0%;
    padding-top: 0%;
    left: 94%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
}

.background div:nth-child(3) {
    background: linear-gradient(132deg, #1FCFC3 0.00%, #1F91CF 100.00%);
    width: 30%;
    padding-top: 30%;
    left: 80%;
    top: 80%;
    transform: translateX(-50%) translateY(-50%);
}
/* Container should have position: relative to position tooltip correctly */
.info-button, .refresh-but {
  position: relative; /* Ensure the tooltip is positioned relative to the button */
}


.info-button .tooltip-text {
  
  visibility: hidden;
  width: 250px;
  background-color: #f0f0f0;
  color: #000;
  text-align: center;
  border-radius: 6px;
  padding: 5px 9px 5px 9px;
  position: absolute;
  z-index: 1;
  bottom: 100%; /* Position the tooltip above the button */
  left: 50%;
  transform: translateX(-50%);
  opacity: 0;
  transition: opacity 0.3s ease, visibility 0.3s ease;
  font-size: 23px;
  margin-bottom: 5px;
  pointer-events: none; /* Prevent tooltip from interfering with mouse events */
}

/* Show the tooltip when hovering over the button */
.info-button:hover .tooltip-text,
.info-button:focus .tooltip-text,
.refresh-but:hover .tooltip-text,
.refresh-but:focus .tooltip-text {
  visibility: visible;
  opacity: 1;
}

/* Tooltip Text for Refresh Button (appears below) */
.refresh-but .tooltip-text {
  font-family: raleway;
  visibility: hidden;
  width: 230px;
  background-color: #fff;
  color: #000;
  text-align: center;
  border-radius: 6px;
  padding: 5px 9px 5px 9px;
  position: absolute;
  z-index: 1;
  top: 100%; /* Position the tooltip below the button */
  left: 50%;
  transform: translateX(-50%);
  opacity: 0;
  transition: opacity 0.3s ease, visibility 0.3s ease;
  font-size: 23px;
  margin-top: 5px; /* Adjust the distance below the button */
  pointer-events: none; /* Prevent tooltip from interfering with mouse events */
}

/* Optional: Delay the tooltip appearance */
.info-button:hover .tooltip-text,
.refresh-but:hover .tooltip-text {
  transition-delay: 0.3s; /* Delay before showing */
}

.info-button .tooltip-text,
.refresh-but .tooltip-text {
  transition-delay: 0s; /* No delay on hiding */
}


.social-media-sidebar {
  position: fixed;
  top: 50%; /* Centered vertically */
  right: 1.5%; /* Adjust as needed */
  transform: translateY(-50%); /* Ensures vertical centering */
  display: flex;
  flex-direction: column;
  gap: 15px; /* Space between icons */
  z-index: 1000; /* High enough to be on top of other elements */
}

.social-icon{
  /* color: #0d6efd !important; */
  color: #6ea5f7 !important;

}


.custom-dialog-tutorial{
  overflow: hidden !important;
  overflow-y: hidden !important;
}

/* .MuiDialogContent-root{
  overflow: hidden !important;
} */

.custom-dialog-tutorial-iframe-container{
  overflow: hidden !important;
  overflow-y: hidden !important;  
}

  
/* .css-kw13he-MuiDialogContent-root{
  overflow-y: hidden !important;
} */

.MuiDialogContent-root {
  overflow-y: hidden !important;
}




  @media (max-width: 768px) {
    .whole-area {
      /* margin-top: 55.5vh !important; */
      /* margin-top: 62vh !important; */

    }
    /* .whole-area.app-mobile {
      margin-top: 22vh !important;
    } */

    .centered-box {
      transform: translate(0%, 0%)  !important;
      display: flex !important;

      align-items: center !important;
      justify-content: center !important;
      position: absolute !important;
    }
    .logo-container-home {
      transform: translate(0%, 0%)  !important; 
      bottom: 30% !important;
      margin-top: -750px !important;
      scale: 1.4 !important;
      gap: 25px

    }
    .logo-icon-home {
      margin-left: -30px !important;
      margin-right: 30px !important;
    }
    .curate-text{
      transform: translate(0%, 0%)  !important; 
      display: flex !important;
      margin-top: -80px !important;

      align-items: center !important;
      justify-content: center !important;
      position: absolute !important;
      /* left: 0% !important; */
      
    }
    .text-div {
      display: flex !important;
      align-items: center !important;
      left: 50%;
      
    }
    .home-features{
      /* transform: translate(0%, 0%)  !important;  */
      left: -480% !important;
      position: absolute !important;
      margin-top: -260px !important;
      font-size: 15px !important;
      scale: 1.5;
      flex-direction: column !important; /* Stack items vertically */
      align-items: center !important; /* Center items horizontally */
      text-align: center;
      width: 100%;
      line-height: 24px;
    }
    .store-text {
      margin-left: 10% !important;
      transform: translate(0%, 0%)  !important; 
      margin-right: 0% !important;


    }
    .share-text {
      margin-left: 0 !important;
      margin-right: 0% !important;

    }
    .discover-text {
      margin-left: 0 !important;
      margin-right: 0% !important;

    }
    /* .custom-dialog-tutorial-title{
      margin-top: 20px !important;
    } */
    .scroll-text  {
      /* transform: translate(0%, 0%)  !important;  */
      /* display: flex !important; */
      /* position: absolute !important; */
      /* left: 29% !important; */
      bottom: 55.5% !important;
      left: 27% !important;
      /* transform: translate(29%, 140%)  !important; */
      /* align-items: center !important; */
      justify-content: center !important;
      white-space: nowrap !important;
      margin-right: 0% !important;
      margin-left: 0% !important;
    }
    .scroll-down-to-interact {
      /* transform: translate(0%, 0%)  !important;  */
      /* display: flex !important; */
      /* position: absolute !important; */
      /* left: 29% !important; */
      bottom: 50% !important;
      left: 27% !important;
      /* transform: translate(29%, 140%)  !important; */
      /* align-items: center !important; */
      justify-content: center !important;
      white-space: nowrap !important;
      margin-right: 0% !important;
      margin-left: 0% !important;
    }


    .scroll-to-top {
      /* top: -580px !important; */
      bottom: 83% !important;
      /* right: 41% !important; */
      /* transform: translate(0%, 0%) */
      left: 50% !important;
      transform: translate(-50%, 0%) !important;
    }
    .icon-button {
     margin-left: -14px !important;
    }
    .refresh-but {
      margin-top: 10px !important;
    }
    .download-dropdown{
      opacity: 1 !important;
    }
    /* .download-home-buttons */
    .home-button-container
    ,
    .download-home-buttons {
      position: fixed !important;
      top: 85px !important;  /* Adjust as needed */
      left: 50% !important;
      transform: translateX(-50%) !important;
      display: flex !important;
      flex-direction: row !important;
      justify-content: center !important;
      align-items: center !important;
      z-index: 1000 !important;
    }
  
    /* Remove fixed positioning from the individual buttons so they follow the flex layout */
    .download-home-buttons > * {
      position: static !important;
      transform: none !important;
      margin: 0 10px; /* Adds space between the buttons */
      z-index: 1000 !important;


    }
  .custom-dialog-tutorial{
    min-width: 100% !important;
    max-width: 100% !important;
    overflow: hidden !important;
  }


  .css-kw13he-MuiDialogContent-root{
    margin-top: -12px !important;
    padding: 0px 5px 5px 5px !important;
  }
  }