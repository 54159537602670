html, body {
    overflow-x: hidden; /* Prevent horizontal scrolling */
    overflow-y: hidden; /* Prevent vertical scrolling */
    height: 100%; /* Ensure full height */
    margin: 0; /* Remove default margin */
    padding: 0; /* Remove default padding */
    font-family: raleway;
    font-family: 'Raleway', sans-serif;

  }

  .no-select {
    -webkit-touch-callout: none; /* Disable callout, e.g., phone numbers in Safari/Chrome */
    -webkit-user-select: none;   /* Disable text selection in Safari/Chrome */
    -khtml-user-select: none;    /* Disable text selection in Konqueror */
    -moz-user-select: none;      /* Disable text selection in Firefox */
    -ms-user-select: none;       /* Disable text selection in Internet Explorer/Edge */
    user-select: none;           /* Disable text selection in non-prefixed browsers */
  }
  

.profile-modal .modal-dialog {
    max-width: 600px; /* Ensure the modal's width does not exceed 600px */
  }
  
  .profile-modal .modal-header {
    border-bottom: none; /* Ensure there's no bottom border on the modal header */
    font-family: 'Raleway', sans-serif;

  }
  
  .profile-modal .modal-content {
    height: 600px; /* Set the height to 600px */
    max-height: 600px; /* Ensure the modal's height does not exceed 600px */
    overflow: hidden;
  }
  
  .profile-modal .modal-body {
    height: calc(100% - 56px); /* Adjust based on header and footer height */
    max-height: calc(100% - 86px);
    overflow-y: auto; /* Enable scrolling if content exceeds height */
    border-top: none;
    font-family: 'Raleway', sans-serif;

  }
  
  .profile-modal .nav-pills {
    display: flex;
    flex-direction: column; /* Keep tabs vertical */
    align-items: center; /* Center align tabs horizontally */
    justify-content: center; /* Center align tabs vertically */
    height: 100%; /* Ensure the nav container takes the full height of the parent */
    margin: auto; /* Optional: Center the nav container within its parent */
    margin-top: -10px;
    width: 30%;
    border-right: none;
    position: relative; /* Position relative to hold border */
  }
  
  .profile-modal .nav-border {
    position: absolute;
    right: 0;
    top: 20%; /* Adjust the top margin to shorten the border */
    width: 1px;
    height: 60%; /* Set the border height to 60% */
    background-color: #dee2e6; /* Border color */
  }
  
  .profile-modal .nav-pills .nav-item {
    width: 100%; /* Ensure each nav item takes full width */
  }
  
  .profile-modal .nav-pills .nav-link {
    border-radius: 5px;
    color: #aaaaaa; /* Default font color */
    padding: 9px 15px;
    background-color: transparent; /* Ensure no background color initially */
    transition: color 0.3s ease, background-color 0.3s ease; /* Smooth transition */
    width: 100%; /* Make the button take full width of the column */
    text-align: center;
  }
  
  .profile-modal .nav-pills .nav-link:hover {
    color: black; /* Font color on hover */
    background-color: #F2F2F3; /* Background color when active */
  }
  
  .profile-modal .nav-pills .nav-link.active {
    background-color: #D5D5D2; /* Background color when active */
    color: black; /* Font color remains black when active */
  }
  
  .profile-modal .tab-content {
    padding-top: 0; /* Ensure content starts at the top */
    width: 90%;
  }
  
  .profile-modal .tab-pane {
    padding-top: 20px; /* Alternatively, you can add padding to each tab pane */
    width: 90%;
    margin: 0 auto;
  }
  
  .ml-3 {
    margin-left: 1rem;
    position: relative;
  }
  
  .modal-title {
    font-weight: bold;
  }
  
  .logout-button {
    position: absolute;
    bottom: 20px;
    width: 100%; /* Ensure the container takes the full width */
    display: flex;
    justify-content: center; 
  }
  


  .profile-modal .modal-body .save-changes-btn {
    background-color: #bbbbbb;
    border-color: #bbbbbb;
    color: #ffffff;
    pointer-events: none; /* Disable hover effects */
}

.profile-modal .modal-body .save-changes-btn:enabled {
    background-color: #e4e4e4;
    border-color: #e4e4e4;
    pointer-events: auto; /* Re-enable hover effects */
    cursor: pointer; /* Show pointer cursor when enabled */
}


.curr-password-label, 
#floatingCurrentPassword ~ label,
#floatingNewPassword ~ label,
#floatingConfirmNewPassword ~ label {
  font-size: 17px !important;
  margin-bottom: 20px !important;
}


.social-media-container-settings {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

.social-icon {
  color: #6ea5f7;
  transition: color 0.3s, transform 0.3s;
}

.social-icon:hover {
  color: #79b8ff; /* Change to a lighter color on hover */
  transform: scale(1.1); /* Slightly enlarge on hover */
}

.privacy-toggle-group {
  /* The outermost Form.Group styles */
  margin-top: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 13px 5px;
  display: flex;
  justify-content: center;   /* Center horizontally */
  align-items: center;       /* Center vertically  */
  margin-bottom: 20px;
}

.privacy-toggle-container {
  /* The container holding both labels + switch */
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem; /* or whatever spacing you prefer */
}

/* Each label is a vertical stack of text */
.privacy-label {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  line-height: 1.2;
  font-size: 1rem;
  white-space: normal;
}


/* Style the switch if needed (optional) */
#privacy-switch {
  transform: scale(1.1); /* optionally make it bigger or smaller */
  margin: 0 0.5rem;      /* reduce gap around the switch */
}

/* --- Media Queries for narrower devices --- */
@media (max-width: 410px) {
  .privacy-label {
    font-size: 0.9rem; /* scale down if necessary */
  }
}

@media (min-width: 769px) {
  .privacy-label {
    flex-direction: row;
    white-space: nowrap;
    gap: 4px; /* optional space between "Private" and "Account" */
  }
  .save-changes-pub-priv{
    margin-left: 25px !important;
  }
}




@media (max-width: 768px) {
  .logo-container-settings {
    transform: translate(-85px, 3px) !important; 
    margin-bottom: -200px !important;
    margin-right: -200px !important;
  }
  
  /* .profile-modal-cc {
    padding: 30px !important;
  } */
  .profile-modal-body{
    padding: 0px !important;
    margin: 0px !important;
    box-sizing: content-box !important;
  }

  .save-changes-pub-priv{
    /* margin-left: 30px !important; */
  }

  .privacy-description{
    margin-top: 22px !important;
  }
  .save-changes-container{
    transform: translate(-10%, -25px) !important;
  }
  .email-form{
    margin-top: 15px !important;
  }
  .close-button {
    transform: translate(0%, 0px) !important; 
  }
  .close-account-link{
    font-size: 14px !important;
  }
  .close-account-description{
    font-size: 13px !important;
  }
  .logo-showcase-settings{
    height: 44px !important ;
}
.logo-icon-settings {
    height: 31px !important ;
    margin-top: 6.5px !important;
    margin-left: -10px !important;
}
.settings-title {
    margin-top: -10px !important;
}
  .logout-button {
    width: 130px !important;
    padding: 0 15px;
    white-space: nowrap; 
    right: calc(53.5% + 3px);
    transform: translate(50%, 35px); 
    margin-top: 20px; 
  }
  .save-changes {
    transform: translate(31px, 7px); 
  }
 
  .delete-account-container{
    transform: translate(0, 3px) !important; 
    font-size: 10px !important;
    overflow: auto;
  }

  
  .contact-us-button {
     /* right: calc(46.5% - 0px);  */
     /* transform: translateX(-50%) !important; */
     /* transform: translateX(calc(-50% + 0px)) !important; */
     /* right: calc(40.5% - 0px) !important; */
     /* right: calc(40.5% - 100px) !important; */
     transform: translateX(calc(-150% + 25vw)) !important; 

     width: 125px !important;
  }
  .tutorial-button {
    transform: translateX(calc(-150% + 25vw)) !important; 

     width: 125px !important;
  }
  .help-buttons{
    margin-left: 9% !important;
  }

  .social-media-container-settings {
    margin-left: -5.5% !important;
  }
  .demo-button-settings{
    /* margin-left: 20% !important;   */
  }
  .demo-button-settings {
    transform: translateX(calc(-150% + 25vw)) !important; 
     width: 125px !important;
  }
  .youtube-icon-settings{
   /* height: 90px !important; */
   /* width: 90px !important; */
   /* scale: 2.5 !important; */
  }
}

/* 
@media (max-width: 439px) {
  .privacy-toggle-group {
    scale: 0.9 !important;
  }

} */


@media (max-width: 410px) {
  #floatingCurrentPassword ~ label,
  #floatingNewPassword ~ label,
  #floatingConfirmNewPassword ~ label {
    font-size: 15px !important;
    margin-top: 3px !important;
  }
}
