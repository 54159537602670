@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@200;300;400;500;600&display=swap');


.no-select {
    -webkit-touch-callout: none; /* Disable callout, e.g., phone numbers in Safari */
    -webkit-user-select: none;   /* Disable text selection in Safari */
    -khtml-user-select: none;    /* Disable text selection in Konqueror */
    -moz-user-select: none;      /* Disable text selection in Firefox */
    -ms-user-select: none;       /* Disable text selection in Internet Explorer/Edge */
    user-select: none;           /* Disable text selection in non-prefixed browsers */
  }
  

  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.4); /* Semi-transparent overlay */
    z-index: 10; /* Ensure it's behind the modal but above the background */
    pointer-events: none; /* Prevent interactions on the background */
  }


  select {
    pointer-events: auto !important;
    display: block !important;
    visibility: visible !important;
    height: auto !important;
    width: auto !important;
  }
  

select {
  border-radius: 8px; /* Rounded border for the dropdown itself */
  z-index: 9999 !important;
  position: relative;
  width: 100%;
  padding: 0px;
  background-color: white;
}

.full-width-button.change-collection-pressed {
  background-color: #ccc; /* Darkened background */
  color: #000; /* Text color when pressed */
}

/* Remove the hover effect when the button is pressed */
.full-width-button:not(.change-collection-pressed):hover {
  background-color: #ddd; /* Adjust hover background */
}

.dropdown-option.pressed-option {
  background-color: #ccc; /* Darkened background */
  color: #000; /* Text color when pressed */
}


  .dropdown-options {
    display: flex;
    flex-direction: column;
    overflow-x: hidden;
  }
  
  /* Style for individual dropdown option */
  .dropdown-option {
    background-color: white; /* Default background */
    color: black; /* Default text color */
    padding: 5px 0px;
    text-align: center;
    cursor: pointer;
    transition: background-color 0.2s ease, transform 0.2s ease; /* Add fade transition */
    border-radius: 4px; /* Border radius for each option */
    margin: 5px 0;
    width: 100%;
  }
  
  .dropdown-option:not(.current-option):hover {
    background-color: #ddd !important; /* Grey background on hover */
  }
  
  /* Ensure options with the 'current-option' class retain their blue background on hover */
  .dropdown-option.current-option:hover {
    background-color: #D1DBFF !important; /* Keep the blue background on hover */
  }
  /* Optional: Remove selection highlighting */
  .no-select {
    user-select: none;
  }

  .dropdown-options {
    display: flex !important;
    flex-direction: column !important;
    overflow-x: hidden !important;
  }
  

  .dropdown-options.scrollable {
    display: block !important;
    max-height: 240px !important;
    overflow-y: auto !important;
  }
  
  .context-menu-box,
  .dropdown-box-bx {
    max-height: 500px !important;

  }

  /* Style the entire scrollbar */
::-webkit-scrollbar {
  width: 4px; /* Make scrollbar thinner */
}

/* Style the scrollbar track (background) */
::-webkit-scrollbar-track {
  background: #f1f1f1; /* light background color */
  border-radius: 4px;
}

/* Style the scrollbar thumb (the draggable part) */
::-webkit-scrollbar-thumb {
  background: #bbb; /* dark grey color */
  border-radius: 4px;
}

/* Hover effect for the thumb */
::-webkit-scrollbar-thumb:hover {
  background: #999;
}

.dropdown-options.scrollable {
  touch-action: pan-y;
}


.submenu {
  overscroll-behavior: contain;
}

.dropdown-options.scrollable {
  overscroll-behavior: contain;
}

.context-submenu-wrapper {
  /* touch-action: pan-y; */
  touch-action: manipulation;
  overscroll-behavior: contain;
}

.context-submenu-wrapper {
  -webkit-touch-callout: none;
}
