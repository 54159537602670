/* Ensure the container spans the full viewport */
.home-phone-container {
    position: relative;
    width: 100%;
    height: 100vh; /* or min-height: 100vh if needed */
    overflow: hidden;
    margin: 0;
    padding: 0;
  }
  
  /* The dreamy background container */
  .dreamy-background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    
    /* Create multiple radial gradients that will appear as "blobs". */
    background: radial-gradient(circle at 30% 50%, 
                rgba(255, 192, 203, 0.4), transparent 50%) 
                0 0 / 100% 100%,
                radial-gradient(circle at 70% 60%, 
                rgba(173, 216, 230, 0.4), transparent 50%) 
                0 0 / 100% 100%,
                radial-gradient(circle at 40% 80%, 
                rgba(255, 160, 122, 0.4), transparent 50%) 
                0 0 / 100% 100%;
  
    /* A soft background base color to fill in any gaps */
    background-color: #fefaff;
  
    /* We’ll animate the positions of these gradients */
    animation: dreamyBlobs 5s ease-in-out infinite;
    z-index: -1; /* So it's behind everything */
  }
  
  /* Keyframes to shift the background positions (and thus the blobs) */
  @keyframes dreamyBlobs {
    0% {
      background-position: 30% 50%, 70% 60%, 40% 80%;
    }
    25% {
      background-position: 33% 55%, 65% 65%, 43% 77%;
    }
    50% {
      background-position: 35% 45%, 75% 55%, 50% 83%;
    }
    75% {
      background-position: 28% 60%, 68% 58%, 37% 75%;
    }
    100% {
      background-position: 30% 50%, 70% 60%, 40% 80%;
    }
  }
  

  @media (max-width: 768px) {
    .whole-area {}
  .here-phone{
    margin-top: -170px !important;
  }
  .container-phone{
    margin-bottom: -405px !important;
  }
  .phone-navbar{
    margin-top: 40px !important;
  }
}